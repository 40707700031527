<template>
    <div class="bg-color">
        <Navbar />
        <Header :category="'press'" :bannerTitle="bannerTitle" :breadCrumb="breadCrumb" />
        <AlbumBody :bannerTitle="bannerTitle" />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Navbar from 'components/DefaultHome/KECC/Common/NavbarStyle'
    import Header from 'components/DefaultHome/KECC/Common/Header'
    import AlbumBody from 'components/DefaultHome/KECC/Organizers/Album/AlbumBody'
    import FooterTop from 'components/DefaultHome/KECC/Common/FooterTop?v=1.0'
    import FooterBottom from 'components/DefaultHome/KECC/Common/BacktopButton'

    export default {
        name: 'PressAlbumMain',
        metaInfo() {
            return {
                title: this.$t("navbar.menu_3_2"),
            }
        },
        components: {
            Navbar,
            Header,
            AlbumBody,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                bannerTitle: this.$t('navbar.menu_3_2'),
                breadCrumb: [{
                    name: this.$t('navbar.menu_3'),
                }],
            }
        },
    }
</script>

<style>
    .bg-color {
        background-color: #eff4f5;
    }
</style>